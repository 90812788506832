// Poppins
@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: url(../../assets/fonts/Poppins/Poppins-Thin.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: url(../../assets/fonts/Poppins/Poppins-ExtraLight.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url(../../assets/fonts/Poppins/Poppins-Light.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url(../../assets/fonts/Poppins/Poppins-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url(../../assets/fonts/Poppins/Poppins-Medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url(../../assets/fonts/Poppins/Poppins-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url(../../assets/fonts/Poppins/Poppins-Bold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: url(../../assets/fonts/Poppins/Poppins-ExtraBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url(../../assets/fonts/Poppins/Poppins-Black.woff2);
  font-display: swap;
}

// Inter
@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url(../../assets/fonts/Inter/Inter-Thin.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url(../../assets/fonts/Inter/Inter-ExtraLight.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url(../../assets/fonts/Inter/Inter-Light.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: normal;
  src: url(../../assets/fonts/Inter/Inter-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(../../assets/fonts/Inter/Inter-Medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(../../assets/fonts/Inter/Inter-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(../../assets/fonts/Inter/Inter-Bold.woff2);
  font-display: swap;
}

$fonts: (
  body: 'Inter',
  heading: 'Poppins',
);

:export {
  @each $key, $value in $fonts {
    #{unquote($key)}: $value;
  }
}
