@import './colors';
@import './fonts';

::selection {
	background-color: white;
	color: map-get($colors, 'black');
}

body {
	background-color: map-get($colors, 's-100');
	background-size: cover;
}

$ghost-text: rgba(166, 174, 190, 0.595);

h1 {
	$size: 5.9vw;

	pointer-events: none;
	user-select: none;
	z-index: 9999;
	padding: 0;
	margin: 0;
	font-family: map-get($fonts, 'heading');
	color: $ghost-text;
	font-size: $size;
	font-weight: 600;
	letter-spacing: -2.2px;
	line-height: $size * 0.9;
	filter: drop-shadow(0px 0px 20px rgba(11, 7, 26, 0.6));
}

h2 {
	padding-top: 15px;
	padding-bottom: 30px;
	text-align: center;
	margin: 0;
	font-family: map-get($fonts, 'body');
	font-size: 1.8rem;
	padding-left: 15px;
	padding-right: 15px;
	letter-spacing: -0.7px;
	font-weight: 400;
	color: map-get($colors, 'white');
	z-index: 1;
}

h3 {
	letter-spacing: -0.25px;
	font-weight: 200;
	color: map-get($colors, 'white');
}

h4 {
	font-family: map-get($fonts, 'body');
	letter-spacing: -0.25px;
	font-weight: 400;
	color: map-get($colors, 'white');
	text-align: center;
}

p {
	margin: 0;
	font-family: map-get($fonts, 'body');
	color: map-get($colors, 'white');
	font-size: 1.2rem;
	text-align: center;
}

a {
	text-decoration: none;
}

.map {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
}

.wrapper-A {
	padding-top: 9vh;
	padding-bottom: 25px;
	padding-left: 80px;
	margin-top: 100px;
}

.wrapper-B {
	position: absolute;
	padding-left: 45px;
	padding-right: 40px;
	width: 35%;
	z-index: 9999;

	top: 0px;
	right: 0;
	bottom: 0;
	background-color: rgba(map-get($colors, 'black'), 78%);
	backdrop-filter: blur(80px);
}

.decoration-1 {
	user-select: none;
	-webkit-user-drag: none;
	position: absolute;
	left: -160px;
	bottom: 20px;
	transform: scale(0.43) rotate(90deg) scaleX(-1);
}

.decoration-2 {
	user-select: none;
	-webkit-user-drag: none;
	position: absolute;
	top: -140px;
	right: -90px;
	transform: scale(0.33) scaleX(-1);
}

.logo {
	user-select: none;
	-webkit-user-drag: none;
}

.avatar {
	width: 130px;
	height: 130px;
}

.highlight {
	position: relative;
	color: map-get($colors, 'white');
}

.highlight-2 {
	font-style: italic;
	font-weight: 500;
	color: map-get($colors, 'a-400');
}

.highlight-3 {
	position: relative;
	color: map-get($colors, 'p-300');
}

.highlight-4 {
	position: relative;
	color: map-get($colors, 'a-400');
}

.arrival {
	color: map-get($colors, 's-400');
}

.bold {
	font-weight: 600;
}

.footer {
	padding-top: 80px;
	text-align: center;

	p {
		font-family: map-get($fonts, 'body');
		letter-spacing: -0.25px;
		font-weight: 400;
		text-align: center;
		color: map-get($colors, 's-600');
		font-size: 0.9rem;
	}
}

.signup-form-field-wrapper {
	position: relative;
	padding: 14px 0 0;
	margin-top: 10px;
}

.signup-form-field {
	font-family: map-get($fonts, 'body');
	font-weight: 400;
	font-size: 1.2em;
	background-color: rgba(map-get($colors, 'white'), 7%);
	backdrop-filter: blur(30px);
	outline: 0;
	color: white;
	border: 2px solid transparent;
	padding: 13px 7px;
	border-radius: 10px;
	width: 350px;

	&::placeholder {
		color: transparent;
		padding: 12px 8px;
	}

	&:placeholder-shown ~ .signup-form-label {
		cursor: text;
		padding-top: 28px;
		padding-left: 12px;
		padding-right: 12px;
		pointer-events: none;
		user-select: none;
	}

	&:not(:placeholder-shown) ~ .signup-form-label {
		font-size: 1em;
		margin-top: -10px;
		color: map-get($colors, 'p-300');
		font-weight: 500;
	}
}

.signup-form-label {
	display: block;
	position: absolute;
	top: 0;
	font-size: 1.2em;
	color: rgba(map-get($colors, 'white'), 30%);
	transition: 0.2s;
}

.signup-form-field:focus {
	~ .signup-form-label {
		display: block;
		position: absolute;
		color: map-get($colors, 'p-300');
		transition: 0.2s;
	}

	border-color: map-get($colors, 'p-300');
}

.signup-form-field {
	&:required,
	&:invalid {
		box-shadow: none; // resets input
	}
}

.signup-form-field-error {
	font-family: map-get($fonts, 'body');
	color: map-get($colors, 'a-400');
	text-transform: lowercase;
	font-weight: 500;
	margin-top: 5px;
}

.signup-button {
	width: 100%;
}

.registered-header {
	color: map-get($colors, 'a-400');
	font-weight: 700;
	font-size: 40px;
}

.registered-description {
	color: map-get($colors, 's-500');
	font-size: 20px;
}

.contact-us-button {
	width: 50%;
}

.link {
	font-size: 1em;
	color: map-get($colors, 'p-200');
	font-weight: 600;
	text-decoration: underline;
}

/* MARK: mobile screens */
@media (max-width: 900px) {
	* {
		overflow-x: hidden;
	}

	$size: 3.5rem;

	h1 {
		font-size: $size;
		line-height: $size * 1.05;
	}

	.wrapper-A {
		padding-top: 120px;
		padding-bottom: 25px;
		padding-left: 25px;
		padding-right: 25px;
		margin: 0 auto;
	}

	.wrapper-B {
		position: relative;
		padding-top: 70px;
		padding-left: 10px;
		padding-right: 10px;
		width: 100%;
		height: 100%;
		border-radius: 0;
		margin: 0;
	}

	.decoration-1 {
		user-select: none;
		-webkit-user-drag: none;
		position: absolute;
		display: block;
		left: 0px;
		bottom: 0px;
		height: 60px;
		transform: none;
	}

	.decoration-2 {
		user-select: none;
		-webkit-user-drag: none;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 80px;
		transform: none;
	}

	.overlay {
		background-color: rgba(12, 10, 40, 0.4);
	}

	.name {
		color: map-get($colors, 'white');
	}

	.logo {
		width: 100px;
		height: 100px;
	}

	.footer {
		width: 100%;
		text-align: center;
	}
}

/* DEBUG ONLY */
// * {
//   outline: 1px red solid !important;
//   visibility: visible !important;
// }
